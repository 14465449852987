<template>
  <div>
    <h1 class="mt-5">SQL Reporter</h1>
  </div>
</template>

<script>
import { /*state,mutations*/ } from '@/store'

export default {
    components: { },

    computed: {
        /*state( ) {
            return state
        }*/
    },

    /*created( ) {
        mutations.readSetting( )
    },*/

    methods: { }
}
</script>