<template>
  <div>
    <DefaultViewAnonymousUser v-show="!$can('logout', 'Auth')" />
    <DefaultViewLoggedInUser v-show="$can('logout', 'Auth')" />
  </div>
</template>

<script>
import { /*state,*/ mutations } from '@/store'
import DefaultViewAnonymousUser from './DefaultViewAnonymousUser.vue'
import DefaultViewLoggedInUser from './DefaultViewLoggedInUser.vue'
import storage from "@/lib/gms/storage"

export default {
    components: {
        DefaultViewAnonymousUser,
        DefaultViewLoggedInUser
    },

    computed: {
        /*state( ) {
            return state
        }*/
    },

    created( ) { },

    methods: { },

    mounted( ) {
        let databaseId = null

        // Test na povinné parametry
        databaseId = this.$route.query.db

        if (databaseId == null) {
            mutations.createInvalidDbParameterError( )

            return
        }

        delete (this.$route.query.db)

        //console.log("databaseId", databaseId)

        // Test na uloženou autorizaci
        let storageAuthAvailable = storage.getItem("auth_available")

        //console.log("storageAuthAvailable", storageAuthAvailable)

        if (storageAuthAvailable && storageAuthAvailable[databaseId]) {
            storage.setItem("auth", storageAuthAvailable[databaseId])

            //console.log("storageAuthAvailable do auth", storageAuthAvailable[databaseId])

            this.$router.push({ name: "reports", params: {  } })
        } else {
            // Odstranění aktuálního připojení k DB
            storage.removeItem("auth")

            this.$router.push({ name: "auth-login", params: {  } })
        }

        // Uložení vstupních parametrů
        mutations.setDatabaseId(databaseId)
    }
}
</script>